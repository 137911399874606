import axios from "axios";
import { BASE_URL, CRM_API } from "utils/constants";
import { getCRMTokenValue } from "utils/util";

export class APICRM {
  static async getTokenCRM() {
    let result;
    const URL = `${BASE_URL}/api/apicrm/get-current-token`;

    await axios
      .get(URL)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "error",
          message: err.message,
        };
      });

    return result;
  }

  static async addTokenCRM(params) {
    let result = null;
    const URL = `${BASE_URL}/api/apicrm/create-or-update-token`;

    await axios
      .post(URL, params)
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "error",
          message: err.message,
        };
      });

    return result;
  }

  static async SendMapInfo(params) {
    console.log('ENTR');
    let result = null;
    const URL2 = `${CRM_API}/api/measuring/quote-measures`;
    console.log(URL2);

    // console.log(URL2);
    await axios
      .post(URL2, params, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "x-token": getCRMTokenValue()
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "error",
          message: err.message,
        };
      });

    return result;
  }

  static async SendUrlReport(params) {
    let result = null;
    const URL2 = `${CRM_API}/api/measuring/send-report-url`;

    // console.log(URL2);
    await axios
      .post(URL2, params, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "x-token": getCRMTokenValue()
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "error",
          message: err.message,
        };
      });

    return result;
  }

  static async UpdateCompanycamId(params) {
    let result = null;
    const URL = `${CRM_API}/api/measuring/update-companycamid`;

    await axios
      .post(URL, params, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "x-token": getCRMTokenValue()
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((err) => {
        result = {
          status: "error",
          message: err.message,
        };
      });

    return result;
  }
}
